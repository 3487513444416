import React from "react"
import PropTypes from "prop-types"
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import { price_formatter, percent_formatter } from '../Helpers'

const getSuggestionValue = suggestion => suggestion.symbol;
const renderSuggestion = (suggestion, { query }) => {
  const suggestionText = `${suggestion.shortname} (${suggestion.symbol})`;
  const matches = AutosuggestHighlightMatch(suggestionText, query);
  const parts = AutosuggestHighlightParse(suggestionText, matches);

  return (
    <span className={'suggestion-content ' + suggestion.twitter}>
      <span className="name">
        {
          parts.map((part, index) => {
            const className = part.highlight ? 'highlight' : null;

            return (
              <span className={className} key={index}>{part.text}</span>
            );
          })
        }
      </span>
    </span>
  )
}

class TickerTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      tickers: props.tickers,
      value: '',
      suggestions: [],
    };
  }

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ticker: suggestion })
    };

    fetch('/tickers', requestOptions)
      .then(response => response.json())
      .then(data => this.setState({ tickers: [data, ...this.state.tickers] }));
  }

  clickedDelete = (i) => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    };

    fetch(`/tickers/${this.state.tickers[i].id}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        let tickers = [...this.state.tickers]
        tickers.splice(i, 1)
        this.setState({tickers: tickers})
      });
  }

  onSuggestionsFetchRequested = ({ value }) => {
    fetch(`/tickers/search?q=${value}`)
      .then(response => response.json())
      .then(data => this.setState({ suggestions: data }))
  }

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  renderTickers = () => {
    return this.state.tickers.map((ticker, x) => {
      return (
        <tr key={x} className="ticker">
          <td>
            <div className="media">
              <img className="mr-3" width="48" src={ticker.logo_url} alt="" />
              <div className="media-body">
                <h6 className="mt-0">
                  <a href={ticker.ticker_url}>{ticker.symbol}</a>
                </h6>
                {ticker.name}
              </div>
            </div>
          </td>
          {ticker.returns_summary.map((data, y) => {
            if (this.state.amount && y == 0) {
              return (
                <td key={y}>
                  {price_formatter(this.state.amount)}
                </td>
              )
            } else if (data.price) {
              return (
                <td key={y}>
                  {this.state.amount ? (
                    <span>{price_formatter((data.performance + 1) * parseFloat(this.state.amount))}</span>
                  ) : (
                    <span>{price_formatter(data.price)}</span>
                  )}
                  <br />
                  <span className={`percentage ${data.performance > 0 ? 'gain' : 'loss'}`}>
                    {data.performance > 0 ? `+${percent_formatter(data.performance, y == 0 ? 2 : 0)}` : `${percent_formatter(data.performance, y == 0 ? 2 : 0)}`}
                  </span>
                </td>
              )
            } else {
              return (
                <td className="text-center" key={y} colSpan={6-y}>
                  <span>First traded</span>
                  <br />
                  <span>{data.date}</span>
                </td>
              )
            }
          })}
          <td>
            <i onClick={() => this.clickedDelete(x)} className="fa fa-times delete-icon loss"></i>
          </td>
        </tr>
      )
    })
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  render () {
    const { value, suggestions } = this.state

    const inputProps = {
      placeholder: 'Add a stock or cryptocurrency',
      value,
      onChange: this.onChange,
      className: 'form-control'
    };

    return (
      <React.Fragment>
        <h2>Compare Investments</h2>
        <p>Add stocks, crypto, ETFs or other assets to see how each performed against each other historically if you had invested 1 week, 1 month, 3 months, 6 months, 1 year or 5 years ago.</p>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <ul className="navbar-nav w-100">
            <li className="nav-item">
              <div className="form-inline ticker-form mr-4">
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionSelected={this.onSuggestionSelected}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputProps}
                />
              </div>
            </li>
            <li className="nav-item">
              <div className="form-inline">
                <span>Compare growth of a</span>
                <div className="input-group ml-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <input 
                    className="form-control mr-sm-2"
                    type="text"
                    placeholder="1000"
                    name="amount"
                    value={this.state.amount}
                    onChange={(e) => this.setState({amount: e.target.value})}
                  />
                </div>
                <span> investment</span>
              </div>
            </li>
          </ul>
        </nav>
        <div className="table-responsive">
          <table className="table table-striped comparison">
            <thead>
              <tr>
                <th>Bought</th>
                <th>{this.state.amount ? 'Investment' : 'Now'}</th>
                <th>1w ago</th>
                <th>1mo ago</th>
                <th>3mo ago</th>
                <th>1y ago</th>
                <th>5y ago</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
             {/* <tr>
                <td colSpan="8">
                  <div className="form-inline ticker-form">
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionSelected={this.onSuggestionSelected}
                      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={renderSuggestion}
                      inputProps={inputProps}
                    />
                    <button onClick={this.addAsset} className="btn btn-primary ml-2">Add asset</button>
                  </div>
                </td>
              </tr>*/}
              {this.renderTickers()}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default TickerTable
