import React from "react"
import PropTypes from "prop-types"
import { price_formatter, percent_formatter } from '../Helpers'
import classNames from 'classnames/bind';

class TickerDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ticker: props.ticker,
    };
  }

  render () {
    const { ticker } = this.state

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12">
            <div className="block">
              <div className="media">
                <a className="media-left mr-3" target="_blank" href={ticker.url}>
                  <img width="80" src={ticker.logo_url} />
                </a>
                <div className="media-body">
                  <h2 className="media-heading">{ticker.name} ({ticker.symbol})</h2>
                  {!!ticker.description && (
                    <p className="summary">{`${ticker.description.substring(0, 200)}...`}</p>
                  )}
                  {!!ticker.url && (
                    <a href={ticker.url} className='btn btn-primary'>Visit Website</a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-lg-12">
            <div className="block">
              <h4>
                {price_formatter(ticker.price)}
                <span className={classNames("ml-2", {loss:  ticker.returns_summary[0].performance < 0}, {gain: ticker.returns_summary[0].performance > 0})}>
                  ({percent_formatter(ticker.returns_summary[0].performance)})
                </span>
              </h4>
              <div className="table-responsive">
                <table className="table table-striped comparison">
                  <thead>
                    <tr>
                      <th>1w ago</th>
                      <th>1mo ago</th>
                      <th>3mo ago</th>
                      <th>1y ago</th>
                      <th>5y ago</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ticker.returns_summary.map((data, y) => {
                      if (y == 0) {
                        return null
                      } else if (data.price) {
                        return (
                          <td key={y}>
                            <span>{price_formatter(data.price)}</span>
                            <br />
                            <span className={`percentage ${data.performance > 0 ? 'gain' : 'loss'}`}>
                              {data.performance > 0 ? `+${percent_formatter(data.performance)}` : `${percent_formatter(data.performance)}`}
                            </span>
                          </td>
                        )
                      } else {
                        return (
                          <td className="text-center" key={y} colSpan={6-y}>
                            <span>First traded</span>
                            <br />
                            <span>{data.date}</span>
                          </td>
                        )
                      }
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TickerDetails
